<template>
  <div v-if="loading" class="error-page flex-row align-items-center">
    <spinner :text="textSpinnerValue" />
  </div>
  <div v-else-if="!loading" class="animated fadeIn">
    <!-- @slot Utilice este `slot` para el contenido del layout -->
    <slot />
  </div>
</template>

<script>
/**
 * Componente loader layout
 * @version `ready`
 */
export default {
  name: 'CatalogLoaderLayout',

  props: {
    /**
     * Si es `true` se muestra el loader component sino se esconde y se muestra
     * el contenido del `slot`
     */
    loading: {
      type: Boolean,
      default: false
    },

    textSpinner: {
      type: String,
      default: ''
    }
  },

  computed: {
    textSpinnerValue() {
      return this.textSpinner || `${this.$t('message.generic.loading.data')}...`;
    }
  }
};
</script>
